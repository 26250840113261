<template>
	<div id="admin-home">
		<el-menu
			:default-active="activeIndex"
			class="el-menu-demo"
			mode="horizontal"
			@select="handleSelect"
		>
			<el-menu-item index="1">首页</el-menu-item>
			<el-submenu index="2">
				<template slot="title">院系管理</template>
				<el-menu-item index="2-0">院系选项情况查看</el-menu-item>
				<el-menu-item index="2-1">院系列表</el-menu-item>
				<el-menu-item index="2-2">添加院系</el-menu-item>
				<el-menu-item index="2-3">已删除院系</el-menu-item>
			</el-submenu>
			<el-submenu index="3">
				<template slot="title">协会管理</template>
				<el-menu-item index="3-1">协会列表</el-menu-item>
				<el-menu-item index="3-2">添加协会</el-menu-item>
				<el-menu-item index="3-3">已删除协会</el-menu-item>
			</el-submenu>
			<el-submenu index="4">
				<template slot="title">比赛管理</template>
				<el-menu-item index="4-1">比赛列表</el-menu-item>
				<el-menu-item index="4-2">添加比赛</el-menu-item>
				<el-menu-item index="4-3">已删除比赛</el-menu-item>
			</el-submenu>
			<el-submenu index="5">
				<template slot="title">材料审阅</template>
				<el-menu-item index="5-1">协会赛事章程</el-menu-item>
				<el-menu-item index="5-2">协会文件</el-menu-item>
				<el-menu-item index="5-3">院系体育活动</el-menu-item>
				<el-menu-item index="5-4">院系工作计划</el-menu-item>
			</el-submenu>
			<el-menu-item index="6">赛季管理</el-menu-item>
			<el-menu-item index="7">比赛结果审核</el-menu-item>
			<div id="hello-logout-button">
				<div id="logout-button" @click="logout()">
					<el-link> 退出<i class="el-icon-switch-button"></i> </el-link>
				</div>
				<div id="hello-user" v-if="this.userinfo">
					你好, {{ this.userinfo.username }}
				</div>
			</div>
		</el-menu>
		<div class="line"></div>
		<div id="content">
			<router-view></router-view>
		</div>
	</div>
</template>

<style scoped>
#content {
	margin-top: 15px;
	width: 80%;
	margin-left: auto;
	margin-right: auto;
}
#hello-user {
	float: right;
	height: 60px;
	line-height: 60px;
	margin: 0 16px 0 0;
	border-bottom: 2px solid transparent;
	color: #909399;
}
#logout-button {
	float: right;
	height: 60px;
	line-height: 60px;
	margin: 0 20px 0 0;
	border-bottom: 2px solid transparent;
	color: #909399;
}
#hello-logout-button:focus {
	outline: none;
}
</style>

<script>
import mixin from "../mixins/universalmixins.js";
const AdminHome = {
	name: "AdminHome",

	data() {
		return {
			query: null,
			activeIndex: "1",
		};
	},
	methods: {
		handleSelect(key) {
			// console.log(key, keyPath);
			switch (key) {
				case "1":
					this.$router.push("/admin");
					break;
				case "2-0":
					this.$router.push("/admin/all-select");
					break;
				case "2-1":
					this.$router.push({ name: "DeptList", query: { deleted: "false" } });
					break;
				case "2-2":
					this.$router.push("/admin/add-dept");
					break;
				case "2-3":
					this.$router.push({ name: "DeptList", query: { deleted: "true" } });
					break;
				case "3-1":
					this.$router.push({ name: "ClubList", query: { deleted: "false" } });
					break;
				case "3-2":
					this.$router.push("/admin/add-club");
					break;
				case "3-3":
					this.$router.push({ name: "ClubList", query: { deleted: "true" } });
					break;
				case "4-1":
					this.$router.push({ name: "GameList", query: { deleted: "false" } });
					break;
				case "4-2":
					this.$router.push("/admin/add-game");
					break;
				case "4-3":
					this.$router.push({ name: "GameList", query: { deleted: "true" } });
					break;
				case "6":
					this.$router.push("/admin/change-season");
					break;
				case "5-1":
					this.$router.push("/admin/review-rules");
					break;
				case "5-2":
					this.$router.push("/admin/review-files");
					break;
				case "5-3":
					this.$router.push("/admin/review-sports");
					break;
				case "5-4":
					this.$router.push("/admin/review-plan");
					break;
				case "7":
					this.$router.push("/admin/resultlist");
					break;
				default:
					break;
			}
		},
	},
	mixins: [mixin],
};

export default AdminHome;
</script>
