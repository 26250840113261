import axios from 'axios'
import qs from 'qs'

const universalmixins = {
    data() {
        return {
            'isUniversalmixinsLoaded': true,
            userinfo: null,
            yearinfo: null,
        }
    },
    methods: {
        messageBox(msg, type) {
            this.$message[type](msg);
        },
        
        /*
        * Log out and clear the jwt/token in localstorage
        * Params: None
        * Returns: None
        * TODO: Use vuex to store jwt/token 
        */
        logout() {
            axios({
                url: '/api/user/logout',
                method: 'POST',
                headers: {
                    'Authorization': localStorage.getItem('token')
                },
                data: qs.stringify({'userid': this.userinfo.userid}),
            }).then(() => {
                // console.log(res);
                this.messageBox("登出成功，" + this.userinfo.username, "success");
                localStorage.removeItem("token");
                this.userinfo = null;
                this.$router.push('/login');
            }).catch((err) => {
                this.messageBox(
                    "登出失败， " + err?.response?.data?.Message ?? "网络断开或神秘错误",
                    "error"
                );
                console.log('Log out Failed');
                console.log(err.response);
                localStorage.removeItem("token");
                this.userinfo = null;
                this.$router.push('/login');
            });
        },

        async getCurrentYear() {
            // Return current season year if no exception else -1
            // Require having logged in
            return await new Promise((resolve, reject)=>{if (localStorage.getItem('token')) {
                axios.defaults.headers.common["Authorization"] = localStorage.getItem('token');
                axios({
                    url: '/api/game/getyear',
                    method: 'GET',
                    headers: {
                        'Authorization': localStorage.getItem('token')
                    },
                }).then((res) => {
                    this.yearinfo = res.data;
                    resolve(res.data);
                }).catch((err) => {
                    this.messageBox(
                        "获取当前赛季失败， " + err?.response?.data?.Message ?? "网络断开或神秘错误",
                        "error"
                    );
                    console.log('Get current year Failed');
                    console.log(err.response);
                    reject(null);
                })
            } else {
                    this.$router.push('/login');
                }
            });
        },

        getHellouser() {
            // test login status and return information of user, if not proper, return null
            if (localStorage.getItem('token')) {
                axios.defaults.headers.common["Authorization"] = localStorage.getItem('token');
                axios({
                    url: '/api/hello-user',
                    method: 'GET',
                }).then((res) => {
                    // console.log(res.data);
                    this.userinfo = res.data;
                    return res.data;
                }).catch((err) => {
                    this.messageBox(
                        "获取用户信息失败， " + err?.response?.data?.Message ?? "网络断开或神秘错误",
                        "error"
                    );
                    console.log('Hello-user Failed');
                    console.log(err.response);
                    return null;
                })
            } else {
                this.$router.push('/login');
            }
        },

        getStatOf(year) {
            // Return the statistics of the input param, current year if param is null
            axios({
                url: '/api/stat/get?year=' + String(year),
                method: 'GET',
                headers: {
                    'Authorization': localStorage.getItem('token')
                },
            }).then((res) => {
                // console.log(res);
                return res?.data?.year ?? -1;
            }).catch((err) => {
                this.messageBox(
                    "获取赛季成绩失败， " + err?.response?.data?.Message ?? "网络断开或神秘错误",
                    "error"
                );
                console.log('Get scores Failed');
                console.log(err.response);
                return -1;
            })
        }
    },
    beforeMount() {
        // console.log('Universal Mixin installed successfully');
        if (localStorage.getItem('token')) {
            axios.defaults.headers.common["Authorization"] = localStorage.getItem('token');
            this.userinfo = this.getHellouser();
            this.yearinfo = this.getCurrentYear();
            // console.log(this.userinfo);
        }
    }

}
export default universalmixins;